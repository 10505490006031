import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
  useQueryClient,
} from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'

import { mergeParams } from '@procsea/common/utils'

import { ApiPaginatedEntityResponse } from 'src/action-creators'
import {
  useDeleteEntityMutation,
  useEntitiesQuery,
  useEntityQueries,
  useEntityQuery,
  usePaginatedEntitiesQuery as usePaginatedEntitiesQueryUtil,
  usePatchEntityMutation,
  usePostEntityMutation,
  usePutEntityMutation,
} from 'src/queries/entity'
import { ContentType } from 'src/utils'

import { EntityWithId, UseDeleteEntityMutationProps, UseEntityMutationProps } from '../entity.types'
import { getEntityCountFromLastPage, listAllPaginatedEntities } from '../utils'

export interface UseSingleEntityQueryArgs<DataType, QueryParams = {}, TData = DataType> {
  id?: Id | undefined
  queryOptions?: UseQueryOptions<DataType, Error, TData>
  queryParams?: QueryParams
}

export interface UseAllEntitiesQueryArgs<DataType, QueryParams = {}, TData = DataType[]> {
  queryOptions?: UseQueryOptions<DataType[], Error, TData>
  queryParams?: QueryParams
}

export interface UseMultipleEntitiesQueryArgs<DataType, QueryParams = {}> {
  ids?: Id[]
  queryOptions?: UseQueryOptions<DataType, Error>
  queryParams?: QueryParams
}

export interface UsePaginatedEntitiesQueryArgs<DataType, QueryParams = {}, TData = DataType> {
  queryOptions?: UseInfiniteQueryOptions<
    ApiPaginatedEntityResponse<DataType>,
    Error,
    ApiPaginatedEntityResponse<TData>
  >
  queryParams?: QueryParams
}

export interface UsePatchEntityArgs<DataType, Payload = {}> {
  contentType?: ContentType
  queryOptions?: UseEntityMutationProps<Payload, DataType, Payload>['queryOptions']
  updateCache?: boolean
}

export interface UsePostEntityArgs<DataType, Payload = {}> {
  contentType?: ContentType
  queryOptions?: UseEntityMutationProps<Payload, DataType, Payload>['queryOptions']
}

export interface UsePutEntityArgs<DataType, Payload = {}> {
  contentType?: ContentType
  queryOptions?: UseEntityMutationProps<Payload, DataType, Payload>['queryOptions']
  updateCache?: boolean
}

export interface UseDeleteEntityArgs<DataType> {
  queryOptions?: UseDeleteEntityMutationProps<DataType>['queryOptions']
}

export interface GenerateQueriesArgs<DataType, QueryParams = {}, Payload = {}> {
  endpoint: string
  queryKey: string
  useAllEntitiesQueryArgs?: UseAllEntitiesQueryArgs<DataType, QueryParams>
  useDeleteEntityArgs?: UseDeleteEntityArgs<DataType>
  useMultipleEntitiesQueryArgs?: UseMultipleEntitiesQueryArgs<DataType, QueryParams>
  usePaginatedEntitiesQueryArgs?: UsePaginatedEntitiesQueryArgs<DataType, QueryParams>
  usePatchEntityArgs?: UsePatchEntityArgs<DataType, Partial<Payload>>
  usePostEntityArgs?: UsePostEntityArgs<DataType, Payload>
  usePutEntityArgs?: UsePutEntityArgs<DataType, Payload>
  useRecursiveQueryArgs?: UsePaginatedEntitiesQueryArgs<DataType, QueryParams>
  useSingleEntityQueryArgs?: UseSingleEntityQueryArgs<DataType, QueryParams>
}

export interface UseRecursiveQueryResult<DataType> {
  data: DataType[]
  hasNextPage?: boolean
  isFetchingNextPage: boolean
  isInitialLoading: boolean
  totalDataCount: number
}

export const generateQueries = <
  DataType extends EntityWithId,
  QueryParams = {},
  Payload extends {} = {},
>({
  endpoint,
  queryKey,
  useAllEntitiesQueryArgs,
  useDeleteEntityArgs,
  useMultipleEntitiesQueryArgs,
  usePaginatedEntitiesQueryArgs,
  usePatchEntityArgs,
  usePostEntityArgs,
  usePutEntityArgs,
  useRecursiveQueryArgs,
  useSingleEntityQueryArgs,
}: GenerateQueriesArgs<DataType, QueryParams, Payload>) => {
  const useSingleEntityQuery = <TData>({
    id,
    queryOptions,
    queryParams,
  }: UseSingleEntityQueryArgs<DataType, QueryParams, TData> = {}) =>
    useEntityQuery<DataType, QueryParams, TData>({
      endpoint,
      entityId: id,
      queryKey,
      queryOptions: {
        ...queryOptions,
        enabled: !!id && queryOptions?.enabled,
      },
      queryParams,
    })

  const useAllEntitiesQuery = <TData>({
    queryOptions,
    queryParams,
  }: UseAllEntitiesQueryArgs<DataType, QueryParams, TData> = {}) =>
    useEntitiesQuery<DataType[], QueryParams, TData>({
      endpoint,
      queryKey,
      queryOptions,
      queryParams,
    })

  const useMultipleEntitiesQuery = ({
    ids,
    queryOptions,
    queryParams,
  }: UseMultipleEntitiesQueryArgs<DataType, QueryParams>) =>
    useEntityQueries<DataType, QueryParams>({
      endpoint,
      entityIds: ids,
      queryKey,
      queryOptions: {
        ...queryOptions,
        enabled: !!ids && queryOptions?.enabled,
      } as any,
      queryParams,
    }) as UseQueryResult<DataType, Error>[]

  const usePaginatedEntitiesQuery = <TData>({
    queryParams,
    queryOptions,
  }: UsePaginatedEntitiesQueryArgs<DataType, QueryParams, TData> = {}) =>
    usePaginatedEntitiesQueryUtil<DataType, QueryParams, TData>({
      endpoint,
      queryKey,
      queryParams,
      queryOptions,
    })

  const usePatchEntity = ({
    contentType = ContentType.APPLICATION_JSON,
    queryOptions,
    updateCache = true,
  }: UsePatchEntityArgs<DataType, Partial<Payload>>) => {
    const queryClient = useQueryClient()

    return usePatchEntityMutation<Partial<Payload>, DataType, Partial<Payload>>({
      contentType,
      endpoint,
      queryOptions: {
        ...queryOptions,
        onSuccess: (data, variables, context) => {
          if (updateCache) {
            queryClient.setQueriesData([queryKey, data.id], data)
          } //partial match: replace regardless of queryparams
          queryOptions?.onSuccess?.(data, variables, context)
        },
      },
    })
  }

  const usePostEntity = (
    {
      contentType = ContentType.APPLICATION_JSON,
      queryOptions,
    }: UsePostEntityArgs<DataType, Payload> = {
      contentType: ContentType.APPLICATION_JSON,
    }
  ) => {
    return usePostEntityMutation<Payload, DataType, Payload>({
      contentType,
      endpoint,
      queryOptions,
    })
  }

  const usePutEntity = ({
    contentType = ContentType.APPLICATION_JSON,
    queryOptions,
    updateCache = true,
  }: UsePutEntityArgs<DataType, Payload>) => {
    const queryClient = useQueryClient()

    return usePutEntityMutation<Payload, DataType, Payload>({
      contentType,
      endpoint,
      queryOptions: {
        ...queryOptions,
        onSuccess: (data, variables, context) => {
          if (updateCache) {
            queryClient.setQueriesData([queryKey, data.id], data)
          } //partial match: replace regardless of queryparams
          queryOptions?.onSuccess?.(data, variables, context)
        },
      },
    })
  }

  const useDeleteEntity = ({ queryOptions }: UseDeleteEntityArgs<DataType> = {}) =>
    useDeleteEntityMutation<DataType>({
      endpoint,
      queryOptions,
    })

  const useRecursiveQuery = <TData>({
    queryParams,
    queryOptions,
  }: UsePaginatedEntitiesQueryArgs<DataType, QueryParams, TData> = {}) => {
    const {
      data: result,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      isInitialLoading,
    } = usePaginatedEntitiesQueryUtil<DataType, QueryParams, TData>({
      endpoint,
      queryKey,
      queryParams: { limit: 100, ...queryParams } as QueryParams,
      queryOptions,
    })

    useEffect(() => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage({ cancelRefetch: false })
      }
    }, [hasNextPage, isFetchingNextPage])

    const data = useMemo(() => listAllPaginatedEntities(result) ?? [], [JSON.stringify(result)])

    return {
      data,
      hasNextPage,
      isFetchingNextPage,
      isInitialLoading,
      totalDataCount: getEntityCountFromLastPage(result),
    }
  }

  return {
    useAllEntitiesQuery: <TData = DataType[]>(
      args: UseAllEntitiesQueryArgs<DataType, QueryParams, TData> = {}
    ) =>
      mergeParams<{}, UseQueryResult<TData, Error>>(useAllEntitiesQuery)(useAllEntitiesQueryArgs)(
        args
      ),
    useDeleteEntity: (args: UseDeleteEntityArgs<DataType> = {}) =>
      mergeParams(useDeleteEntity)(useDeleteEntityArgs)(args),
    useMultipleEntitiesQuery: (args: UseMultipleEntitiesQueryArgs<DataType, QueryParams> = {}) =>
      mergeParams(useMultipleEntitiesQuery)(useMultipleEntitiesQueryArgs)(args),
    usePaginatedEntitiesQuery: <TData = DataType>(
      args: UsePaginatedEntitiesQueryArgs<DataType, QueryParams, TData> = {}
    ) =>
      mergeParams<{}, UseInfiniteQueryResult<ApiPaginatedEntityResponse<TData>, Error>>(
        usePaginatedEntitiesQuery
      )(usePaginatedEntitiesQueryArgs)(args),
    usePatchEntity: (args: UsePatchEntityArgs<DataType, Partial<Payload>> = {}) =>
      mergeParams(usePatchEntity)(usePatchEntityArgs)(args),
    usePostEntity: (args: UsePostEntityArgs<DataType, Payload> = {}) =>
      mergeParams(usePostEntity)(usePostEntityArgs)(args),
    usePutEntity: (args: UsePutEntityArgs<DataType, Payload> = {}) =>
      mergeParams(usePutEntity)(usePutEntityArgs)(args),
    useRecursiveQuery: <TData = DataType>(
      args: UsePaginatedEntitiesQueryArgs<DataType, QueryParams, TData> = {}
    ) =>
      mergeParams<{}, UseRecursiveQueryResult<TData>>(useRecursiveQuery)(useRecursiveQueryArgs)(
        args
      ),
    useSingleEntityQuery: <TData = DataType>(
      args: UseSingleEntityQueryArgs<DataType, QueryParams, TData> = {}
    ) =>
      mergeParams<{}, UseQueryResult<TData, Error>>(useSingleEntityQuery)(useSingleEntityQueryArgs)(
        args
      ),
  }
}
