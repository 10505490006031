import { GenerateQueriesArgs, generateQueries } from 'src/queries/queryGenerator'
import { Dashboard, DashboardList, UserType } from 'src/types'

export const ANALYTICS_DASHBOARDS_ENDPOINT = [UserType.BUYER, 'analytics', 'dashboards'].join('/')

export const ANALYTICS_DASHBOARDS_QUERY_KEY = 'analyticsDashboards'

export interface GetBuyerDashboardsQueryParams {
  buyerCompanyId?: Id | Id[]
  limit?: number
}

const generateDashboardListQueriesConfig: GenerateQueriesArgs<
  DashboardList,
  GetBuyerDashboardsQueryParams
> = {
  endpoint: ANALYTICS_DASHBOARDS_ENDPOINT,
  queryKey: ANALYTICS_DASHBOARDS_QUERY_KEY,
}

export const { usePaginatedEntitiesQuery: usePaginatedDashboardsQuery } = generateQueries(
  generateDashboardListQueriesConfig
)

const generateDashboardQueriesConfig: GenerateQueriesArgs<
  Dashboard,
  GetBuyerDashboardsQueryParams
> = {
  endpoint: ANALYTICS_DASHBOARDS_ENDPOINT,
  queryKey: ANALYTICS_DASHBOARDS_QUERY_KEY,
  useSingleEntityQueryArgs: {
    queryOptions: {
      cacheTime: 0,
    },
  },
}

export const { useSingleEntityQuery: useDashboardQuery } = generateQueries(
  generateDashboardQueriesConfig
)
