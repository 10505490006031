import React from 'react'

import { useCurrentMarketplaceId } from 'src/components/presentationals/buyer-dashboard/shared/hooks'
import { useMeQuery } from 'src/queries/seller'
import {
  useBuyerSubscriptionsQuery,
  useMarketplacesQuery,
  useSellerCompanyQuery,
} from 'src/queries/shared'
import { Membership, User, UserType } from 'src/types'

import { useMembershipContext } from './MembershipContext'
import { useAuth } from './authContext'

const UserContext = React.createContext<User | undefined>(undefined)

export const UserProvider = (props: object) => {
  const {
    data: { user },
  } = useAuth()
  return <UserContext.Provider value={user} {...props} />
}

export const useUser = () => React.useContext(UserContext)

interface UseCurrentMembershipArgs<T extends boolean> {
  optional?: T
}

type UseCurrentMembershipResult<T> = T extends true ? Membership | undefined : Membership

export const useCurrentMembership = <T extends boolean = false>({
  optional,
}: UseCurrentMembershipArgs<T> = {}): UseCurrentMembershipResult<T> => {
  const membershipContext = useMembershipContext({ optional })

  return membershipContext?.currentMembership as Membership
}

interface GetUserTypeArgs {
  membership?: Membership
  user?: User
}

export const getUserType = ({ membership, user }: GetUserTypeArgs): UserType => {
  if (!user) return UserType.UNKNOWN
  if (user.isStaff) return UserType.ADMIN
  if (membership?.isBuyer) return UserType.BUYER
  if (membership?.isSeller) return UserType.SELLER

  return UserType.UNKNOWN
}

interface UseUserTypeArgs {
  optional?: boolean
}

interface UseUserTypeResult {
  isBuyer: boolean
  isSeller: boolean
  userType: UserType
}

export const useUserType = ({ optional }: UseUserTypeArgs = {}): UseUserTypeResult => {
  const user = useUser()
  const membership = useCurrentMembership({ optional })
  const userType = getUserType({ membership, user })

  return {
    isBuyer: userType === UserType.BUYER,
    isSeller: userType === UserType.SELLER,
    userType,
  }
}

interface UseUserBuyerCompanyIdArgs {
  optional?: boolean
}

export const useUserBuyerCompanyId = ({ optional }: UseUserBuyerCompanyIdArgs = {}):
  | Id
  | undefined => {
  const membership = useCurrentMembership({ optional })

  return (membership?.isBuyer && membership?.buyerCompanyId) || undefined
}

export const useBuyerBuyerCompanyId = (): Id => {
  const buyerCompanyId = useUserBuyerCompanyId()

  if (!buyerCompanyId) {
    throw new Error('buyerCompanyId must be defined')
  }

  return buyerCompanyId
}

interface UseUserSellerCompanyIdArgs {
  optional?: boolean
}

export const useUserSellerCompanyId = ({ optional }: UseUserSellerCompanyIdArgs = {}):
  | Id
  | undefined => {
  const membership = useCurrentMembership({ optional })

  return (membership?.isSeller && membership?.sellerCompanyId) || undefined
}

export const useSellerSellerCompanyId = (): Id => {
  const sellerCompanyId = useUserSellerCompanyId()

  if (!sellerCompanyId) {
    throw new Error('sellerCompanyId must be defined')
  }

  return sellerCompanyId
}

export const useSeller = () => {
  const sellerCompanyId = useUserSellerCompanyId()
  const { data: seller } = useMeQuery({ enabled: !!sellerCompanyId })

  return seller
}

export const useCurrentSellerCompany = () => {
  const sellerCompanyId = useUserSellerCompanyId()

  const { data: sellerCompany } = useSellerCompanyQuery({ sellerCompanyId })

  return sellerCompany
}

export const useOrganisationId = (): Id => {
  const membership = useCurrentMembership()
  return membership.organisationId
}

export const useUserDefaultLocationId = (): Id | undefined => {
  const membership = useCurrentMembership()

  return membership.defaultLocationId ?? undefined
}

export const useSellerCentralDefaultVerticalId = () => {
  const sellerCompanyId = useUserSellerCompanyId()

  const {
    data: sellerCompany,
    isInitialLoading,
    isLoading,
  } = useSellerCompanyQuery({ sellerCompanyId })

  const isFetchingCentralDefaultVerticalId = isInitialLoading || isLoading
  const centralDefaultVerticalId = sellerCompany?.centralDefaultVerticalId ?? undefined

  return {
    isFetchingCentralDefaultVerticalId,
    centralDefaultVerticalId,
  }
}

export const useCurrentMarketplace = (marketplaceId?: Id) => {
  const currentMarketplaceId = useCurrentMarketplaceId() ?? marketplaceId

  const { data: marketplace } = useMarketplacesQuery({
    queryOptions: {
      select: marketplaces => marketplaces.find(({ id }) => id === currentMarketplaceId),
    },
  })

  return marketplace
}

export const useActiveBuyerSubscription = (marketplaceId?: Id) => {
  const buyerCompanyId = useBuyerBuyerCompanyId()
  const { data: marketplaces = [] } = useMarketplacesQuery()
  const currentMarketplaceId = useCurrentMarketplaceId() ?? marketplaceId

  const { data: buyerSubscription } = useBuyerSubscriptionsQuery({
    queryParams: {
      companyId: buyerCompanyId,
      marketplaceId: marketplaces.map(({ id }) => id),
    },
    queryOptions: {
      select: buyerSubscriptions =>
        buyerSubscriptions.find(
          subscription =>
            subscription.companyId === buyerCompanyId &&
            subscription.marketplaceId === currentMarketplaceId
        ),
    },
  })

  return buyerSubscription
}

export const useConnectedBuyerMembership = () => {
  const user = useUser()
  const currentmembership = useCurrentMembership()

  const buyerMembership = user?.memberships.find(
    membership =>
      membership.isBuyer && membership.organisationId === currentmembership.organisationId
  )

  return buyerMembership
}
